//
// URL Constants from Environment Variables in .env files.
// DO NOT EDIT VALUES HERE !!!!!!!!!!!!!!!!
//

export const constants = {
  app_env: import.meta.env.VITE_ENV,
  api_url: import.meta.env.VITE_API_URL_NEW,
  query_service_url: import.meta.env.VITE_QUERY_SERVICE_URL,
  firebaseUrl: import.meta.env.VITE_FIREBASE_URL,
  url: import.meta.env.VITE_APIURL,
  imgurl: import.meta.env.VITE_IMGURL,
  s3imgurl: import.meta.env.VITE_S3IMGURL,
  fbappid: import.meta.env.VITE_FB_APP_ID,
  fbapivers: import.meta.env.VITE_FB_API_VERS,
  redirecturi: import.meta.env.VITE_REDIRECT_URI,
  widgetUrl: import.meta.env.VITE_WIDGET_URL,
  webchatTestUrl: import.meta.env.VITE_WEBCHAT_TEST_URL,
  agentHelperApiKey: import.meta.env.VITE_AGENT_HELPER_API_KEY,
  cdnUrl: 'https://cdn.botco.ai',
  collabKitAppId: import.meta.env.VITE_COLLAB_KIT_APP_ID,
  collabKitApiKey: import.meta.env.VITE_COLLAB_KIT_API_KEY,
} as const;
