import { axiosClient } from '../../axiosClient';
import type { FallbackIntentsAnalytics } from '../types';

type PathParams = {
  agentId: number;
  deployId: number;
};

type QueryParams = {
  startDate?: string;
  endDate?: string;
};

export const getFallbackIntents = ({
  path: { agentId, deployId },
  query: { startDate, endDate },
}: {
  path: PathParams;
  query: QueryParams;
}) =>
  axiosClient.get<FallbackIntentsAnalytics>(
    `analytics/intent-events/intents/fallback/${agentId}/${deployId}`,
    {
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    }
  );
