import { Commentable } from '@collabkit/react';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { routes } from '~/constants/routes';

import { Header } from './Header/Header';
import { IdleDialog } from './IdleDialog';
import { SideNav } from './SideNav/SideNav';
import { useLayout } from './useLayout';
import { Collaborator } from '../Collaborator';
import { SpinnerLoader } from '../SpinnerLoader';

const Blocks = React.lazy(() =>
  import('~/containers/Blocks/Blocks').then((module) => ({
    default: module.Blocks,
  }))
);

const Broadcast = React.lazy(() =>
  import('~/containers/Broadcast').then((module) => ({
    default: module.Broadcast,
  }))
);

const Deployments = React.lazy(() =>
  import('~/containers/Launch/Deployments').then((module) => ({
    default: module.Deployments,
  }))
);
const Deploy = React.lazy(() =>
  import('~/containers/Launch/Deploy').then((module) => ({
    default: module.Deploy,
  }))
);
const Training = React.lazy(() =>
  import('~/containers/Training').then((module) => ({
    default: module.Training,
  }))
);
const AITraining = React.lazy(() =>
  import('~/containers/Training/AITraining').then((module) => ({
    default: module.AITraining,
  }))
);

const BotcoDashboard = React.lazy(() =>
  import('~/containers/BotcoDashboard/Dashboard').then((module) => ({
    default: module.BotcoDashboard,
  }))
);
const BotcoDashboardBeta = React.lazy(() =>
  import('~/containers/BotcoDashboard/DashboardBeta').then((module) => ({
    default: module.DashboardBeta,
  }))
);
const KnowledgeBase = React.lazy(() =>
  import('~/containers/KnowledgeBase/KnowledgeBase').then((module) => ({
    default: module.KnowledgeBase,
  }))
);
const Settings = React.lazy(() =>
  import('~/containers/Settings').then((module) => ({
    default: module.Settings,
  }))
);
const ConversationsListPage = React.lazy(
  () =>
    import('~/containers/Conversations/ConversationsList/ConversationsListPage')
);
const AgentPreview = React.lazy(() =>
  import('~/containers/Conversations/ConversationPreview/AgentPreview').then(
    (module) => ({ default: module.AgentPreview })
  )
);
const OverView = React.lazy(() =>
  import('~/containers/Conversations/OverView').then((module) => ({
    default: module.Overview,
  }))
);
const NewForm = React.lazy(() =>
  import('~/containers/Conversations/NewForm/Form').then((module) => ({
    default: module.NewForm,
  }))
);
const Contacts = React.lazy(() =>
  import('~/containers/Contacts').then((module) => ({
    default: module.Contacts,
  }))
);
const LegacyContacts = React.lazy(() =>
  import('~/containers/Contacts/Legacy/Contacts/Contacts').then((module) => ({
    default: module.Contacts,
  }))
);
const LegacyContactDetails = React.lazy(() =>
  import('~/containers/Contacts/Legacy/ContactDetails/ContactDetails').then(
    (module) => ({
      default: module.ContactDetails,
    })
  )
);
const HelpCenter = React.lazy(() =>
  import('~/containers/HelpCenter').then((module) => ({
    default: module.HelpCenter,
  }))
);

const TrainingDetails = React.lazy(() =>
  import('~/containers/Training/TrainingDetails').then((module) => ({
    default: module.TrainingDetails,
  }))
);

const InstaStackAIPlayground = React.lazy(() =>
  import('~/containers/InstaStack/AIPlayground').then((module) => ({
    default: module.InstaStackAIPlayground,
  }))
);

const InstaStackUrlData = React.lazy(() =>
  import('~/containers/InstaStack/Data/components/UrlData').then((module) => ({
    default: module.InstaStackUrlData,
  }))
);

const InstaStackData = React.lazy(() =>
  import('~/containers/InstaStack/Data').then((module) => ({
    default: module.InstaStackData,
  }))
);

const AuthoringWizard = React.lazy(() =>
  import('~/containers/AuthoringWizard').then((module) => ({
    default: module.AuthoringWizard,
  }))
);

const useStyles = makeStyles((theme) => ({
  loader: {
    width: 200,
    height: 200,
    top: 'calc(50% - 100px)',
    position: 'absolute',
    left: 'calc(50% - 100px)',
  },
  container: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  draggableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  routeContainer: {
    flexGrow: 1,
    minHeight: 0,
    backgroundColor: theme.palette.grey['300'],

    '& > div': {
      padding: theme.spacing(5, 6, 3),
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
  },
}));

export const Layout = () => {
  const [xDelta, setXDelta] = React.useState<number>();
  const { showComponent, logout, openIdleDialog } = useLayout();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { setNodeRef } = useDroppable({
    id: 'layout-droppable',
  });
  const classes = useStyles();
  const location = useLocation();

  if (!showComponent) {
    return (
      <div className={classes.container}>
        <SideNav />
        <SpinnerLoader className={classes.loader} />
      </div>
    );
  }

  const handleDrop = (event: DragEndEvent) => {
    setXDelta((delta) => (delta ?? 0) + event.delta.x);
  };

  return (
    <div className={classes.container}>
      <SideNav />
      <DndContext
        onDragEnd={handleDrop}
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
      >
        <div className={classes.draggableContainer} ref={setNodeRef}>
          <Collaborator.Provider>
            <Header />
            <div id="main-container" className={classes.routeContainer}>
              {openIdleDialog && <IdleDialog onLogout={logout} open />}
              <Commentable objectId={`${location.pathname}${location.search}`}>
                <React.Suspense
                  fallback={<SpinnerLoader className={classes.loader} />}
                >
                  <Switch>
                    <Route
                      exact
                      path={routes.chatbots.path}
                      component={ConversationsListPage}
                    />
                    <Route
                      exact
                      path={routes.chatbotDetails.path}
                      component={AgentPreview}
                    />
                    <Route
                      exact
                      path={routes.chatbotOverview.path}
                      component={OverView}
                    />
                    <Route
                      path={routes.chatbotFormFormP1.path}
                      component={AgentPreview}
                    />
                    <Route
                      path={[
                        routes.chatbotForm.path,
                        routes.chatbotNewForm.path,
                      ]}
                      component={NewForm}
                    />
                    <Route
                      path={routes.chatbotSequence.path}
                      component={AgentPreview}
                    />
                    <Route
                      path={routes.dashboardLegacy.path}
                      component={BotcoDashboard}
                    />
                    <Route
                      path={routes.dashboard.path}
                      component={BotcoDashboardBeta}
                    />
                    <Route
                      path={routes.newDeploymentBeta.path}
                      component={Deploy}
                    />
                    <Route
                      path={routes.deployments.path}
                      component={Deployments}
                    />
                    <Route path={routes.blocks.path} exact component={Blocks} />
                    <Route
                      exact
                      path={routes.settings.path}
                      component={Settings}
                    />
                    <Route
                      path={routes.helpCenter.path}
                      component={HelpCenter}
                    />
                    <Route
                      path={routes.knowledgeBase.path}
                      component={KnowledgeBase}
                    />
                    <Route path={routes.contacts.path} component={Contacts} />
                    <Route
                      path={routes.contactLegacyDetails.path}
                      component={LegacyContactDetails}
                    />
                    <Route
                      path={routes.contactsLegacy.path}
                      component={LegacyContacts}
                    />
                    <Route
                      path={routes.aiTraining.path}
                      exact
                      component={AITraining}
                    />
                    <Route
                      path={routes.training.path}
                      exact
                      component={Training}
                    />
                    <Route
                      path={routes.trainingDetails.path}
                      exact
                      component={TrainingDetails}
                    />
                    <Route
                      path={routes.instaStackAIPlayground.path}
                      exact
                      component={InstaStackAIPlayground}
                    />
                    <Route
                      path={routes.instaStackData.path}
                      exact
                      component={InstaStackData}
                    />
                    <Route
                      path={routes.instaStackUrlData.path}
                      exact
                      component={InstaStackUrlData}
                    />
                    <Route
                      path={routes.authoringWizard.path}
                      component={AuthoringWizard}
                    />
                    <Route path={routes.broadcast.path} component={Broadcast} />
                  </Switch>
                </React.Suspense>
              </Commentable>
            </div>
            <Collaborator.Sidebar xDelta={xDelta} />
          </Collaborator.Provider>
        </div>
      </DndContext>
    </div>
  );
};
