import { createStyles, makeStyles } from '@material-ui/core';

import { drawerWidth, headerHeight } from '~/constants';

const DASHBOARD_BANNER_HEIGHT = 20;
export const DASHBOARD_FILTERS_HEIGHT = 86 + DASHBOARD_BANNER_HEIGHT;

export const useDashboardFiltersStyles = makeStyles((theme) =>
  createStyles({
    container: {
      top: headerHeight,
      left: `calc(${drawerWidth}px + ${theme.spacing(6)}px)`,
      right: theme.spacing(6),
      height: DASHBOARD_FILTERS_HEIGHT,
      padding: theme.spacing(2.5, 2, 0, 2),
      zIndex: theme.zIndex.appBar,
      position: 'fixed',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 1px 4px rgba(3, 27, 137, 0.15)',
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
    filterWrapper: {
      height: theme.spacing(5),
      border: `1px solid ${theme.palette.grey['600']}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0.5, 1.75),
      whiteSpace: 'nowrap',
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    label: {
      width: 'calc(100% - 20px)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: `${theme.typography.fontWeightLight} !important` as any,
    },
    calendar: {
      width: 20,
      height: 20,
      color: theme.palette.grey['900'],
    },
    hintTextWrapper: {
      marginTop: theme.spacing(-1),
    },
    hintText: {
      color: theme.palette.grey['600'],
      marginLeft: theme.spacing(1),
    },
    checkboxRoot: {
      width: '100%',
      margin: 0,
    },
  })
);
