import * as yup from 'yup';

import { AttributeConditionsDto } from '~/__generated__/query-service';
import { AttributeConditions } from '~/types/query-service-helpers';

import { Attribute } from './http/agent/types';

export * from 'yup';

/**
 * Safe number transform for yup
 */
export const safeNumber = () =>
  yup.number().transform((_, originalValue) => {
    if (originalValue == null || originalValue === '') return null;
    if (typeof originalValue === 'number') return originalValue;

    const numberValue = Number(originalValue);
    if (Number.isNaN(numberValue)) return null;

    return numberValue;
  });

export const attribute = () =>
  yup
    .object({
      attribute: yup.mixed<Attribute>().nullable(),
      operator: yup
        .mixed<keyof typeof AttributeConditions>()
        .required()
        .default('eq'),
      value: yup.string().nullable(),
    })
    .transform((_, rawValue) => {
      return typeof rawValue === 'string' ? JSON.parse(rawValue) : rawValue;
    });

type AttributeType = yup.InferType<ReturnType<typeof attribute>>;

export const attributes = () =>
  yup
    .object({
      logical_operator: yup
        .mixed<NonNullable<AttributeConditionsDto['logical_operator']>>()
        .oneOf(['and', 'or'])
        .default('and'),
      conditions: yup.array().of<AttributeType>(attribute()).required().min(1),
    })
    .default({ logical_operator: 'and', conditions: [{ operator: 'eq' }] })
    .transform((_, rawValue) => {
      return typeof rawValue === 'string' ? JSON.parse(rawValue) : rawValue;
    });
