import { Box } from '@material-ui/core';
import * as React from 'react';

import { Card } from '@botco/library';

import { AgentName } from './AgentName/AgentName';
import { AgentsListAvatar } from './Avatar/AgentsListAvatar';
import { AgentsListCardMenu } from './Menu/AgentsListCardMenu';
import { AgentType } from '../types';

type PropsType = {
  agent: AgentType;
  className?: string;
  handleSelectAgent: (agent: AgentType) => void;
  handleUnselectAgent: () => void;
  agentTemplateHasChildrenMap: Record<number, boolean>;
};

export const AgentsListCard = (props: PropsType) => {
  const {
    agent,
    agentTemplateHasChildrenMap,
    className,
    handleSelectAgent,
    handleUnselectAgent,
  } = props;

  const [isEditing, setIsEditing] = React.useState(false);

  const toggleIsEditing = () => setIsEditing(!isEditing);

  return (
    <Card height={250} className={className}>
      <AgentsListAvatar
        agent={agent}
        onSelectAgent={handleSelectAgent}
        isEditing={isEditing}
        toggleIsEditing={toggleIsEditing}
      />
      <Box
        position="relative"
        height="30px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AgentName
          agent={agent}
          isEditing={isEditing}
          toggleIsEditing={toggleIsEditing}
        />
        {!isEditing && (
          <AgentsListCardMenu
            agent={agent}
            onEdit={toggleIsEditing}
            templateHasChildren={agentTemplateHasChildrenMap[agent.id]}
            handleUnselectChatbot={handleUnselectAgent}
          />
        )}
      </Box>
    </Card>
  );
};
