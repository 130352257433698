import type { ICreatableDropDownOption } from '@botco/library';

import { Attribute } from './http/agent/types';
import {
  CUSTOM_ATTRIBUTE,
  defaultAttributes,
  DEFAULT_ATTRIBUTE,
  reservedAttributes,
} from '../constants/attributes';

const attributeRegex = /^[a-z_][a-z0-9_]*$/;
export const extractAttributesFromTextRegex = new RegExp(/\$\{.*?\}/g);

export const cleanAttribute = (attribute: string = ''): string =>
  attribute.replace(/\$\{|\}/g, '').trim();

export const isValidAttributeName = (attribute: string = ''): boolean => {
  return !attribute || attributeRegex.test(attribute);
};

export const isReservedAttributeName = (attribute: string = ''): boolean => {
  return reservedAttributes.includes(attribute);
};

const mapAttributeToOption = (
  attribute: Attribute
): ICreatableDropDownOption => ({
  label: attribute.cust_attr_name,
  value: attribute.cust_attr_id ? `${attribute.cust_attr_id}` : undefined,
  group: CUSTOM_ATTRIBUTE,
});

const sortByLabel = (
  a: ICreatableDropDownOption,
  b: ICreatableDropDownOption
) => a.label.localeCompare(b.label);

const filterBySpecialAttributes = (attribute: Attribute) =>
  defaultAttributes.includes(attribute.cust_attr_name);

type TransformAttributeOptions = {
  additionalAttributes?: string[];
};

export const transformAttributes = (
  attributes: Attribute[],
  options?: TransformAttributeOptions
): ICreatableDropDownOption[] => {
  const defaultAttrbs = defaultAttributes.map(
    (attribute): ICreatableDropDownOption => {
      const option = attributes.find(
        (option) => option.cust_attr_name === attribute
      );
      if (option) {
        return {
          ...mapAttributeToOption(option),
          group: DEFAULT_ATTRIBUTE,
        };
      }

      return {
        label: attribute,
        value: undefined,
        group: DEFAULT_ATTRIBUTE,
      };
    }
  );

  const customAttributes = attributes
    .filter((attr) => !filterBySpecialAttributes(attr))
    .map(mapAttributeToOption)
    .sort(sortByLabel);

  const additional =
    options?.additionalAttributes
      ?.map((attribute) => ({
        label: attribute,
        value: undefined,
        group: CUSTOM_ATTRIBUTE,
      }))
      .sort(sortByLabel) ?? [];

  return [...defaultAttrbs, ...additional, ...customAttributes];
};
