export const DEFAULT_LOGO =
  'https://s3.us-west-2.amazonaws.com/botcontent.botco.ai/DEFAULT-1/deploy_webBotIcon_1566112836178.png';

export const CardTypes = {
  TEXT: 1,
  DELAY: 2,
  ATTRIBUTE: 3,
  CAROUSEL: 7,
  API: 8,
  IMAGE: 9,
  VIDEO: 10,
  GOTO: 12,
  HANDOVER: 13,
  QUICK_REPLY: 14,
  DATA_SUBMIT: 15,
  SMS: 16,
  GOAL: 17,
  INSTA_STACK_API: 18,
  SMS_VALIDATION: 19,
  SET_AI: 20,
  FALLBACK: 21,
  INTENT_AI: 22,
} as const;

export type CardTypesValues = EnumValueType<typeof CardTypes>;
