import { Box, createStyles, makeStyles } from '@material-ui/core';

export const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box mb={1}>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://botco.ai/privacy-policy/"
        >
          Privacy Policy
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://botco.ai/terms-and-conditions/"
        >
          Terms and Conditions
        </a>
        <a target="_blank" rel="noreferrer" href="http://botco.ai/">
          Botco.ai
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.botco.ai/help/">
          Help
        </a>
      </Box>
      <span>Copyright 2019-{new Date().getFullYear()} Botco.ai</span>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      marginTop: theme.spacing(4),

      '& a': {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(3),
        textDecoration: 'none',
      },
    },
  })
);
