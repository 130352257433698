import { cardAttributes } from '~/constants';
import { CardTypes, CardTypesValues } from '~/constants/general';
import { ConversationDataR } from '~/redux/reducers/types';

import { parseInstaStackActionBody } from './instaStackUtils';

export const validateCard = (
  card: ConversationDataR['intentCardsData'][number]
) => {
  switch (card.action_type) {
    case CardTypes.DATA_SUBMIT: {
      switch (true) {
        case !card.param1?.trim():
          return {
            valid: false,
            text: cardAttributes[card.action_type].errors.blankText,
          };
        default:
          return {
            valid: true,
            text: '',
          };
      }
    }
    case CardTypes.VIDEO:
    case CardTypes.IMAGE: {
      if (card.param1) return { valid: true, text: '' };
      return {
        valid: false,
        text: cardAttributes[card.action_type].errors.noUrl,
      };
    }
    case CardTypes.QUICK_REPLY:
    case CardTypes.SMS:
    case CardTypes.TEXT: {
      let valid;
      if (!card.param1 || card.param1.trim().length === 0) {
        valid = false;
        return {
          valid,
          text: cardAttributes[card.action_type].errors.blankText,
        };
      }
      return { valid: true, text: '' };
    }
    case CardTypes.CAROUSEL: {
      const carousels = card.intent_carousel ?? [];
      const valid = carousels.every((c) => c.h_choose_title && c.image_url);

      let text: Record<string, { title?: string; image?: string }> = {};

      carousels.forEach((c, idx) => {
        const cardType = card.action_type as CardTypesValues;

        if (!c.h_choose_title || !c.image_url) {
          text = {
            ...text,
            [idx]: {
              title: !c.h_choose_title
                ? (cardAttributes[cardType].errors.noHeading as string)
                : '',
              image: !c.image_url
                ? (cardAttributes[cardType].errors.noImageUrl as string)
                : '',
            },
          };
        }
      });
      return { valid, text };
    }
    case CardTypes.ATTRIBUTE: {
      let valid;
      if (!card.param1 || !card.param2) {
        valid = false;
        return {
          valid,
          text: cardAttributes[card.action_type].errors.noValue,
        };
      }
      return { valid: true, text: '' };
    }
    case CardTypes.GOTO: {
      if (!card?.intent_choices?.length) {
        return { valid: false, text: [0] };
      }

      const invalidIndexes = card.intent_choices
        ?.map((choice, index) => {
          if (choice.action_data) return null;
          return index;
        })
        .filter(Number.isInteger);

      return { valid: !invalidIndexes?.length, text: invalidIndexes };
    }
    case CardTypes.GOAL: {
      let valid;
      if (!card.param1 || card.param1.trim().length === 0) {
        valid = false;
        return {
          valid,
          text: cardAttributes[card.action_type].errors.blankText,
        };
      }
      return { valid: true, text: '' };
    }
    case CardTypes.FALLBACK: {
      let valid;
      if (isWhiteSpaceString(card.param1 || '')) {
        valid = false;
        return {
          valid,
          text: cardAttributes[card.action_type].errors.blankText,
        };
      }
      return { valid: true, text: '' };
    }
    case CardTypes.INSTA_STACK_API: {
      const data = parseInstaStackActionBody(card?.api_action?.api_action_body);

      const valid =
        Boolean(data?.query) &&
        Boolean(data?.params?.index) &&
        Boolean(data?.params?.OpenAIGenerator?.optional_prompt);

      return {
        valid,
        text: {
          prompt: !data?.params?.OpenAIGenerator?.optional_prompt
            ? 'Prompt cannot be blank'
            : '',
          query: !data?.query ? 'Query cannot be blank' : '',
          index: !data?.params?.index ? 'Collection cannot be blank' : '',
        },
      };
    }
    case CardTypes.SMS_VALIDATION: {
      const valid = Boolean(card.param1) && card.intent_choices?.length === 4;

      return {
        valid,
        text: 'Please fill all fields',
      };
    }
    case CardTypes.INTENT_AI: {
      const validIntents =
        card.param2 && card.param2.split(',').filter(Boolean).length >= 3;
      const valid = Boolean(card.param1 && validIntents);

      return {
        valid,
        text: {
          intentsError: !validIntents
            ? 'At least 3 intents must be specified'
            : '',
          fallbackIntentError: !card.param3
            ? 'Please add a fallback intent'
            : '',
        },
      };
    }
    default:
      return { valid: true, text: '' };
  }
};

export const validURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const phoneRegex =
  /^(\+)+([0-9]\d{9}|[0-9]\d{10}|[0-9]\d{11}|[0-9]\d{12}|[0-9]\d{13}|[0-9]\d{14})$/gm;

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!re.test(email);
};

export const isWhiteSpaceString = (str: string) => !/\S/.test(str);

export const isNullOrUndefined = (value: unknown): value is null | undefined =>
  value === null || typeof value === 'undefined';

export const isValidNewOption = (
  inputValue: string,
  selectOptions: Array<{ label: string; value: string | number }>
) => {
  const isNotDuplicated = !selectOptions.find(
    (option) => option.label === inputValue
  );

  const isNotEmpty = !isWhiteSpaceString(inputValue);

  return isNotDuplicated && isNotEmpty;
};
