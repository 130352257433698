import * as yup from 'yup';

export const defaultInstaStackPrompt = `Please answer the question according to the context below, giving all the necessary context. Only answer questions about \${customer_name} and never mention you are an AI language, answer as a human.`;
export const includeTranscriptsPrompt = `Please answer the question according to the context and previous transcript below, giving all the necessary context. \n\n Transcript: \${_transcript}`;

export const InstaStackActionBodySchema = yup.object({
  // eslint-disable-next-line no-template-curly-in-string
  query: yup.string().nullable().default('${_last_input}'),
  params: yup
    .object({
      index: yup.string().nullable(),
      OpenAIGenerator: yup
        .object({
          optional_prompt: yup
            .string()
            .nullable()
            .default(defaultInstaStackPrompt),
          json_mode: yup.string().nullable().default(null),
        })
        .default({}),
      Retriever: yup
        .object({
          top_k: yup.number().default(5),
        })
        .default({}),
      filters: yup
        .object({
          all_documents: yup.boolean().default(false),
        })
        .default({}),
    })
    .default({}),
});

export function parseInstaStackActionBody(body?: string) {
  return InstaStackActionBodySchema.validateSync(JSON.parse(body ?? '{}'));
}
