import { axiosClient } from '../../axiosClient';
import type {
  AnalyticsFilterOptions,
  OverviewMetric,
  OverviewMetricsType,
} from '../types';

export type OverviewMetricParamsType = Partial<AnalyticsFilterOptions> & {
  includeDetails?: boolean;
};

export const getOverviewMetric = (
  metricType: OverviewMetricsType,
  {
    agentId,
    deployId,
    startDate,
    endDate,
    includeDetails,
  }: OverviewMetricParamsType,
  version?: string
) =>
  axiosClient.get<OverviewMetric>(
    `dashboard/${version ? `${version}/` : ''}${metricType}`,
    {
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent: agentId }),
        ...(deployId && { deploy: deployId }),
        include_details: includeDetails ?? true,
      },
    }
  );
