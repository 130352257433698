import { axiosClient } from '../../axiosClient';
import { AnalyticsFilterOptions, OverviewMetricCSV } from '../types';

type ParamsType = Partial<AnalyticsFilterOptions>;

export const getOverviewMetricCSV = ({
  agentId,
  deployId,
  startDate,
  endDate,
}: ParamsType) =>
  axiosClient.get<OverviewMetricCSV[]>('/dashboard/csv', {
    params: {
      ...(startDate && { start_time: startDate }),
      ...(endDate && { end_time: endDate }),
      ...(agentId && { agent: agentId }),
      ...(deployId && { deploy: deployId }),
    },
  });
