import {
  SET_TRANSCRIPT_CONVERSATION_LOADER,
  TRANSCRIPT_CONVERSATION,
  AGENT_INFORMATION,
  GET_TRANSCRIPT_DEPLOYMENT,
} from '../types/dashboard';

const initialState = {
  transcriptConversationLoader: true,
  currentTranscriptConversation: [],
  transcriptDeployment: {},
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSCRIPT_CONVERSATION: {
      return {
        ...state,
        currentTranscript: action.transcript,
        currentTranscriptConversation: action.payload,
        transcriptConversationLoader: false,
      };
    }
    case AGENT_INFORMATION: {
      return {
        currentTranscriptAgentInfo: action.payload,
        ...state,
      };
    }
    case SET_TRANSCRIPT_CONVERSATION_LOADER: {
      return { ...state, transcriptConversationLoader: action.status };
    }
    case GET_TRANSCRIPT_DEPLOYMENT:
      return {
        ...state,
        transcriptDeployment: action.payload || {},
      };
    default:
      return state;
  }
};
