import { constants } from '~/config';
import { BetaMetricCardType } from '~/containers/BotcoDashboard/OverView/constants';

import { firebaseClient } from '../../firebaseClient';
import type { DashboardSettings } from '../types';

export type PostHideMetricBody = {
  accountId: number;
  agentId: number;
  metric: BetaMetricCardType['id'];
};

export const postHideMetric = ({
  accountId,
  agentId,
  metric,
}: PostHideMetricBody) =>
  firebaseClient.post<DashboardSettings>('/hideMetric', {
    orgID: accountId,
    chatbotID: agentId,
    env: constants.app_env,
    metric,
  });
